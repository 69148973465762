<template>
    <client-page>

        <sub-visual
        sh="후원하기"
        tabActive="유산기부"
        bg="/images/sub/visual/sv3.jpg">
        </sub-visual>

        <section class="section">
            <v-container>

                <section class="page-section">
                    <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                        가입기준 & 절차
                    </h4>
                    <div class="mb-20 mb-lg-40">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card outlined color="grey-d">
                                    <div class="pa-20 pa-md-30 px-lg-34 py-lg-40 grey-fa">
                                        <v-row justify-lg="space-between" align="center" class="flex-md-row-reverse">
                                            <v-col cols="12" lg="auto" class="mb-10 mb-lg-0">
                                                <div class="icon-wrap">
                                                    <v-img src="/images/sub/support/legacy/s1-icon1.svg" contain max-height="50" />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" lg="">
                                                <p class="page-text mb-2 mb-lg-4">
                                                    Green Level
                                                </p>
                                                <h5 class="page-text page-text--lg grey-1--text line-height-1 mb-12 mb-lg-24">
                                                    서약후원자
                                                </h5>
                                                <p class="page-text">
                                                    구체적인 후원금을 정하지 않고 <br class="d-none d-lg-block">
                                                    서약서 작성을 통해 유산기부를 약속한 후원자
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card outlined color="grey-d">
                                    <div class="pa-20 pa-md-30 px-lg-34 py-lg-40 grey-fa">
                                        <v-row justify-lg="space-between" align="center" class="flex-md-row-reverse">
                                            <v-col cols="12" lg="auto" class="mb-10 mb-lg-0">
                                                <div class="icon-wrap">
                                                    <v-img src="/images/sub/support/legacy/s1-icon2.svg" contain max-height="50" />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" lg="">
                                                <p class="page-text mb-2 mb-lg-4">
                                                    Honor Level
                                                </p>
                                                <h5 class="page-text page-text--lg grey-1--text line-height-1 mb-12 mb-lg-24">
                                                    공증후원자
                                                </h5>
                                                <p class="page-text">
                                                    구체적인 후원금/물품을 정하고 <br class="d-none d-lg-block">
                                                    유언 공증, 유언대용신탁을 수행한 후원자
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="process-wrap mb-20 mb-md-10">
                        <v-row justify="center" class="row-cols-md-5">
                            <v-col cols="6" sm="4" md="">
                                <div class="d-flex flex-column alilgn-center text-center pa-md-30">
                                    <h3 class="font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24">
                                        01
                                    </h3>
                                    <h5 class="page-text page-text--lg grey-1--text mb-2 mb-lg-4">
                                        후원 및 가입상담
                                    </h5>
                                    <p class="page-text word-keep-all">
                                        기부 절차 및 세부사항안내 <br class="d-none d-lg-block">
                                        (본인 및 가족)
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="4" md="">
                                <div class="d-flex flex-column alilgn-center text-center pa-md-30">
                                    <h3 class="font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24">
                                        02
                                    </h3>
                                    <h5 class="page-text page-text--lg grey-1--text mb-2 mb-lg-4">
                                        상담 및 자문 연계
                                    </h5>
                                    <p class="page-text word-keep-all">
                                        
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="4" md="" class="mt-10 mt-sm-0">
                                <div class="d-flex flex-column alilgn-center text-center pa-md-30">
                                    <h3 class="font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24">
                                        03
                                    </h3>
                                    <h5 class="page-text page-text--lg grey-1--text">
                                        공증 및 기부 서약식
                                    </h5>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="4" md="" class="mt-10 mt-md-0">
                                <div class="d-flex flex-column alilgn-center text-center pa-md-30">
                                    <h3 class="font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24">
                                        04
                                    </h3>
                                    <h5 class="page-text page-text--lg grey-1--text">
                                        레거시클럽 등록
                                    </h5>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="4" md="" class="mt-10 mt-md-0">
                                <div class="d-flex flex-column alilgn-center text-center pa-md-30">
                                    <h3 class="font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24">
                                        05
                                    </h3>
                                    <h5 class="page-text page-text--lg grey-1--text">
                                        유산기부집행
                                    </h5>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <p class="page-text page-text--lg text-center line-height-15">
                        * 유산기부 : 소중한 재산(전부 또는 일부)을 공익을 위해 사회에 기부하기로 유언을 남겨 기부하는 일을 의미합니다.
                    </p>
                </section>

                <section class="page-section">
                    <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                        유산기부 종류
                    </h4>
                    <v-row justify="center" class="ma-md-n20 ma-lg-n50 row-cols-md-5 text-center">
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s2-icon1.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                현금
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s2-icon2.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                부동산
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end mt-10 mt-sm-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s2-icon3.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                주식
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end mt-10 mt-md-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s2-icon4.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                보험금
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 mt-10 mt-md-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s2-icon5.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                현물
                            </h5>
                        </v-col>
                    </v-row>
                </section>

                <section class="page-section">
                    <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                        유산기부 방법
                    </h4>
                    <v-row justify="center" class="ma-md-n20 ma-lg-n50 row-cols-md-5 text-center">
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s3-icon1.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                생전
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s3-icon2.svg" contain max-height="48" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                유증
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end mt-10 mt-sm-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s3-icon3.svg" contain max-height="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                신탁
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 divider--end mt-10 mt-md-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s3-icon4.svg" contain max-width="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                보험
                            </h5>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="pa-md-20 pa-lg-50 mt-10 mt-md-0">
                            <div class="icon--responsive mb-14 mb-lg-28">
                                <div class="icon--responsive__inner">
                                    <v-img src="/images/sub/support/legacy/s3-icon5.svg" contain max-height="60" />
                                </div>
                            </div>
                            <h5 class="page-text page-text--lg grey-1--text line-height-1">
                                경매
                            </h5>
                        </v-col>
                    </v-row>
                </section>
                
            </v-container>
        </section>
        

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import RowImage from "@/components/client/sub/row-image.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        RowImage,
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.icon-wrap{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--v-primary-base);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon--responsive{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
}
.icon--responsive__inner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .divider--end{
        position: relative;
    }
    .divider--end::after{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - 40px);
        top: 20px;
        right: 0;
        background-color: var(--border-color);
    }
}
@media (min-width:1024px){
    .process-wrap .row{
        margin: -7px;
    }
    .process-wrap .row>[class*="col"]{
        padding: 7px;
    }
    .divider--end::after{
        height: calc(100% - 100px);
        top: 50px;
    }
}
@media (min-width:1200px){
    .process-wrap{
        position: relative;
    }
    .process-wrap::before{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 14px);
        height: 24px;
        left: 50%;
        top: calc(50% - 49px);
        transform: translate(-50%,-50%);
        background-image: url(/images/sub/support/legacy/process-arrow.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

</style>