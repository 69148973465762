var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "후원하기",
      "tabActive": "유산기부",
      "bg": "/images/sub/visual/sv3.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('section', {
    staticClass: "page-section"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 가입기준 & 절차 ")]), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 px-lg-34 py-lg-40 grey-fa"
  }, [_c('v-row', {
    staticClass: "flex-md-row-reverse",
    attrs: {
      "justify-lg": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s1-icon1.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('p', {
    staticClass: "page-text mb-2 mb-lg-4"
  }, [_vm._v(" Green Level ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-12 mb-lg-24"
  }, [_vm._v(" 서약후원자 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 구체적인 후원금을 정하지 않고 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 서약서 작성을 통해 유산기부를 약속한 후원자 ")])])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 px-lg-34 py-lg-40 grey-fa"
  }, [_c('v-row', {
    staticClass: "flex-md-row-reverse",
    attrs: {
      "justify-lg": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "icon-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s1-icon2.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('p', {
    staticClass: "page-text mb-2 mb-lg-4"
  }, [_vm._v(" Honor Level ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-12 mb-lg-24"
  }, [_vm._v(" 공증후원자 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 구체적인 후원금/물품을 정하고 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 유언 공증, 유언대용신탁을 수행한 후원자 ")])])], 1)], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "process-wrap mb-20 mb-md-10"
  }, [_c('v-row', {
    staticClass: "row-cols-md-5",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column alilgn-center text-center pa-md-30"
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24"
  }, [_vm._v(" 01 ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text mb-2 mb-lg-4"
  }, [_vm._v(" 후원 및 가입상담 ")]), _c('p', {
    staticClass: "page-text word-keep-all"
  }, [_vm._v(" 기부 절차 및 세부사항안내 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" (본인 및 가족) ")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column alilgn-center text-center pa-md-30"
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24"
  }, [_vm._v(" 02 ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text mb-2 mb-lg-4"
  }, [_vm._v(" 상담 및 자문 연계 ")]), _c('p', {
    staticClass: "page-text word-keep-all"
  })])]), _c('v-col', {
    staticClass: "mt-10 mt-sm-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column alilgn-center text-center pa-md-30"
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24"
  }, [_vm._v(" 03 ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text"
  }, [_vm._v(" 공증 및 기부 서약식 ")])])]), _c('v-col', {
    staticClass: "mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column alilgn-center text-center pa-md-30"
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24"
  }, [_vm._v(" 04 ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text"
  }, [_vm._v(" 레거시클럽 등록 ")])])]), _c('v-col', {
    staticClass: "mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column alilgn-center text-center pa-md-30"
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 line-height-1 primary--text mb-12 mb-lg-24"
  }, [_vm._v(" 05 ")]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text"
  }, [_vm._v(" 유산기부집행 ")])])])], 1)], 1), _c('p', {
    staticClass: "page-text page-text--lg text-center line-height-15"
  }, [_vm._v(" * 유산기부 : 소중한 재산(전부 또는 일부)을 공익을 위해 사회에 기부하기로 유언을 남겨 기부하는 일을 의미합니다. ")])]), _c('section', {
    staticClass: "page-section"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 유산기부 종류 ")]), _c('v-row', {
    staticClass: "ma-md-n20 ma-lg-n50 row-cols-md-5 text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s2-icon1.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 현금 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s2-icon2.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 부동산 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end mt-10 mt-sm-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s2-icon3.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 주식 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s2-icon4.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 보험금 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s2-icon5.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 현물 ")])])], 1)], 1), _c('section', {
    staticClass: "page-section"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 유산기부 방법 ")]), _c('v-row', {
    staticClass: "ma-md-n20 ma-lg-n50 row-cols-md-5 text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s3-icon1.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 생전 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s3-icon2.svg",
      "contain": "",
      "max-height": "48"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 유증 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end mt-10 mt-sm-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s3-icon3.svg",
      "contain": "",
      "max-height": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 신탁 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 divider--end mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s3-icon4.svg",
      "contain": "",
      "max-width": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 보험 ")])]), _c('v-col', {
    staticClass: "pa-md-20 pa-lg-50 mt-10 mt-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "icon--responsive mb-14 mb-lg-28"
  }, [_c('div', {
    staticClass: "icon--responsive__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/legacy/s3-icon5.svg",
      "contain": "",
      "max-height": "60"
    }
  })], 1)]), _c('h5', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1"
  }, [_vm._v(" 경매 ")])])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }